<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="题材" prop="filterTheme">
                <a-select
                  v-model="queryParam.filterTheme"
                  style="width: 100%"
                  placeholder="请选择题材"
                  :options="scriptFilterThemeOptions"
                  @change="selectFilterThemeHandleChange"
                ></a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否置顶人气榜" prop="isPopularTop">
                <a-select
                  placeholder="请选择是否置顶人气榜"
                  style="width: 100%"
                  v-model="queryParam.isPopularTop"
                  allowClear
                >
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否置顶口碑榜" prop="isPopinionsTop">
                <a-select
                  placeholder="请选择是否置顶口碑榜"
                  style="width: 100%"
                  v-model="queryParam.isPopinionsTop"
                  allowClear
                >
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否每日热搜置顶" prop="isHotTop">
                <a-select
                  placeholder="请选择是否每日热搜置顶"
                  style="width: 100%"
                  v-model="queryParam.isHotTop"
                  allowClear
                >`
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="启用状态" prop="isEnable">
                <a-select placeholder="请选择启用状态" style="width: 100%" v-model="queryParam.isEnable" allowClear>
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否封分" prop="isCloseScore">
                <a-select placeholder="请选择是否封分" style="width: 100%" v-model="queryParam.isCloseScore" allowClear>
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否上新" prop="isNew">
                <a-select placeholder="请选择是否上新" style="width: 100%" v-model="queryParam.isNew" allowClear>
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否有角色" prop="isNew">
                <a-select placeholder="请选择是否有角色" style="width: 100%" v-model="queryParam.isRole" allowClear>
                  <a-select-option value="1">是</a-select-option>
                  <a-select-option value="0">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否剧本时长为空" prop="timeLengthIsNull">
                <a-select
                  placeholder="请选择是否为空"
                  style="width: 100%"
                  v-model="queryParam.timeLengthIsNull"
                  allowClear
                >
                  <a-select-option value="1">是</a-select-option>
                  <a-select-option value="0">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {} "
              >
                <a-button
                  style="margin-left: 8px"
                  type="primary"
                  @click="$refs.createForm.handleAdd()"
                  v-hasPermi="['script:script:add']"
                >
                  <a-icon type="plus" />
                  新增
                </a-button>
                <a-button style="margin-left: 8px" type="primary" @click="handleQuery"><a-icon
                  type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['script:script:add']">-->
        <!--          <a-icon type="plus"/>-->
        <!--          新增-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"-->
        <!--                  v-hasPermi="['script:script:edit']">-->
        <!--          <a-icon type="edit"/>-->
        <!--          修改-->
        <!--        </a-button>-->
        <!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['script:script:remove']">-->
        <!--          <a-icon type="delete"/>-->
        <!--          删除-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['script:script:export']">
                  <a-icon type="download" />导出
                </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['script:script:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['script:script:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['script:script:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['script:script:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
        <template slot="cover" slot-scope="text, record">
          <div style="cursor: pointer;">
            <img
              v-if="record.cover"
              :src="record.cover"
              alt=""
              style="width:60px;height:62px;"
              preview
            />
          </div>
        </template>
        <span slot="isEnable" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isEnable" />
        </span>
        <span slot="isCloseScore" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isCloseScore" />
        </span>
        <span slot="isNew" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isNew" />
        </span>
        <span slot="peopleNumber" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          {{ JSON.parse(record.role).length }}
        </span>
        <span slot="isPopularTop" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isPopularTop" />
        </span>
        <span slot="isPopinionsTop" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isPopinionsTop" />
        </span>
        <span slot="isHotTop" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isHotTop" />
        </span>
        <a-col :md="8" :sm="24">
          <a-form-item label="发行时间" prop="releaseTime">
            <a-date-picker style="width: 100%" @change="onDateChange" format="YYYY-MM-DD" allow-clear />
          </a-form-item>
        </a-col>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        :pageSizeOptions="['10', '20', '50', '100']"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
<!--    <img v-if="previewVisible && previewImage" alt="example" style="max-width: 800px; max-height: 750px" :src="previewImage" />-->
  </page-header-wrapper>
</template>

<script>
import { listScript, delScript } from '@/api/script/script'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import { loadFilterOptions } from '@/api/script/scriptFilter'
import { mapGetters } from 'vuex'
import CustomDictTag from '@/components/DictCustomTag'
import ScriptConfigComponents from '@/views/script/script/modules/ScriptConfigComponents.vue'

export default {
  name: 'Script',
  components: {
    ScriptConfigComponents,
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      scriptFilterThemeOptions: [],
      // 预览封面
      previewVisible: false,
      previewImage: null,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        shopId: undefined,
        cover: undefined,
        name: undefined,
        info: undefined,
        label: undefined,
        isDiyAverageHue: undefined,
        averageHue: undefined,
        filterBackground: undefined,
        filterTheme: undefined,
        filterDifficulty: undefined,
        filterSellForm: undefined,
        filterType: undefined,
        personNum: undefined,
        humanNum: undefined,
        womanNum: undefined,
        talkType: undefined,
        talkInfo: undefined,
        author: undefined,
        seller: undefined,
        producer: undefined,
        storyBackground: undefined,
        officialEvaluation: undefined,
        hotValue: undefined,
        hotValueVirtual: undefined,
        likeValue: undefined,
        likeValueVirtual: undefined,
        salesValue: undefined,
        salesValueVirtual: undefined,
        popularValue: undefined,
        popularValueVirtual: undefined,
        themeValue: undefined,
        themeValueVirtual: undefined,
        role: undefined,
        isRole: undefined,
        timeLengthIsNull: undefined,
        isEnable: undefined,
        isCloseScore: undefined,
        isNew: undefined,
        isPopularTop: undefined,
        releaseTime: undefined,
        startTime: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '剧本名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '剧本封面',
          dataIndex: 'cover',
          scopedSlots: { customRender: 'cover' },
          ellipsis: true,
          width: 140,
          align: 'center'
        },
        {
          title: '启用状态',
          dataIndex: 'isEnable',
          scopedSlots: { customRender: 'isEnable' },
          ellipsis: true,
          width: 85,
          align: 'center'
        },
        {
          title: '是否置顶人气榜',
          dataIndex: 'isPopularTop',
          scopedSlots: { customRender: 'isPopularTop' },
          ellipsis: true,
          width: 120,
          align: 'center'
        },
        {
          title: '是否置顶口碑榜',
          dataIndex: 'isPopinionsTop',
          scopedSlots: { customRender: 'isPopinionsTop' },
          ellipsis: true,
          width: 120,
          align: 'center'
        },
        {
          title: '是否每日热搜置顶',
          dataIndex: 'isHotTop',
          scopedSlots: { customRender: 'isHotTop' },
          ellipsis: true,
          width: 130,
          align: 'center'
        },
        {
          title: '是否封分',
          dataIndex: 'isCloseScore',
          scopedSlots: { customRender: 'isCloseScore' },
          ellipsis: true,
          width: 85,
          align: 'center'
        },
        {
          title: '是否上新',
          dataIndex: 'isNew',
          scopedSlots: { customRender: 'isNew' },
          ellipsis: true,
          width: 85,
          align: 'center'
        },
        {
          title: '角色人数',
          dataIndex: 'peopleNumber',
          scopedSlots: { customRender: 'peopleNumber' },
          ellipsis: true,
          width: 60,
          align: 'center'
        },
        {
          title: '发行时间',
          dataIndex: 'releaseTime',
          scopedSlots: { customRender: 'releaseTime' },
          ellipsis: true,
          width: 110,
          align: 'center'
        },
        {
          title: '剧本时长',
          dataIndex: 'estimatedTimeName',
          ellipsis: true,
          width: 100,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 170,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.initScriptFilterThemeHandleOptions()
  },
  computed: { ...mapGetters(['customDict']) },
  watch: {},
  methods: {
    /** 查询剧本列表 */
    getList() {
      this.loading = true
      listScript(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    onDateChange(date, dateString) {
      console.log(date, dateString)
      this.queryParam.startTime = dateString
    },
    handlePreview(file) {
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    initScriptFilterThemeHandleOptions() {
      loadFilterOptions({ type: 2 }).then(res => {
        this.scriptFilterThemeOptions = res.data
      })
    },
    selectFilterThemeHandleChange(value) {
      this.form.filterTheme = value
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        cover: undefined,
        name: undefined,
        info: undefined,
        label: undefined,
        filterBackground: undefined,
        filterTheme: undefined,
        filterDifficulty: undefined,
        filterSellForm: undefined,
        filterType: undefined,
        personNum: undefined,
        humanNum: undefined,
        womanNum: undefined,
        talkType: undefined,
        talkInfo: undefined,
        author: undefined,
        seller: undefined,
        producer: undefined,
        storyBackground: undefined,
        officialEvaluation: undefined,
        hotValue: undefined,
        hotValueVirtual: undefined,
        likeValue: undefined,
        likeValueVirtual: undefined,
        salesValue: undefined,
        salesValueVirtual: undefined,
        popularValue: undefined,
        popularValueVirtual: undefined,
        themeValue: undefined,
        themeValueVirtual: undefined,
        role: undefined,
        isEnable: undefined,
        isCloseScore: undefined,
        isNew: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delScript(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('script/script/export', {
            ...that.queryParam
          }, `script_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
